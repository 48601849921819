<template>
  <div>
    <Popup v-model:show="setTime" position="bottom" :style="{ height: '50%' }">
      <DatetimePicker
        v-model="currentDate"
        :type="dateType"
        title="选择时间"
        @cancel="cancelTimePopup"
        @confirm="selectTime"
      />
    </Popup>
    <DatetimePicker
      v-model="currentDate"
      :type="dateType"
      title="选择完整时间"
      v-show="abc"
    />
  </div>
</template>
<script>
import { Popup, DatetimePicker } from 'vant'
export default {
  components: {
    Popup, DatetimePicker
  },
  data () {
    return {
      setTime: false,
      currentDate: '00:00',
      dateType: 'time',
      abc: false
    }
  },
  methods: {
    show () {
      this.setTime = true
    },
    cancelTimePopup () {
      this.setTime = false
    },
    selectTime () {
      this.$emit('change', this.currentDate)
      this.setTime = false
    }
  }
}
</script>
<style scoped lang="less">

</style>
