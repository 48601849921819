<template>
  <div>
    <Popup
      v-model:show="upStatePopup"
      position="bottom"
      closeable
      :close-on-click-overlay="false">
      <div style="font-size: 14px;font-weight: bold;text-align: center;padding-top: 15px;">选择跟进状态</div>
      <Divider />
      <div style="margin-top: -15px;padding: 0 15px;">
        <van-list>
          <van-cell style="padding: 0;" v-for="(item,index) in optionList" :key="index" @click="selectRow(index)">
            <div class="option_list" :class="[selectKey==index?'select_row':'']">{{ item.name }}</div>
          </van-cell>
        </van-list>
      </div>
      <div style="display: flex;justify-content:center;margin-top: 10px;margin-bottom: 20px;">
        <van-button type="info" block style="width: calc(100% - 30px);" @click="keepSetup">保存</van-button>
      </div>
    </Popup>
  </div>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import { ContactFollowApi } from '@/api/contactFollow'
import { Popup, Divider, Toast } from 'vant'
export default {
  components: {
    Popup, Divider, Toast
  },
  data () {
    return {
      upStatePopup: false,
      optionList: [],
      selectKey: null
    }
  },
  methods: {
    show (contactId) {
      this.contactId = contactId
      this.upStatePopup = true
      this.selectKey = null
      this.getFollowList()
    },
    // 获取跟进状态列表
    getFollowList () {
      ContactFollowApi.stageIndex({
        contactId: this.contactId
      }).then((res) => {
        res.data.forEach((item, index) => {
          if (item.isSelect == 1) {
            this.selectKey = index
          }
        })
        this.optionList = res.data
      })
    },
    // 保存设置
    keepSetup () {
      ContactFollowApi.stageUpdate({
        contactId: this.contactId,
        statusId: this.optionList[this.selectKey].id
      }).then((res) => {
        Toast('设置成功')
        this.$emit('change', true)
        this.upStatePopup = false
      })
    },
    // 选择跟进状态
    selectRow (index) {
      this.selectKey = index
    }
  }
}
</script>
<style scoped lang="less">
.option_list{
  text-align: center;
  padding: 15px 0;
  color: #595959;
  cursor: pointer;
}
.option_list:hover{
  background: #EFFAFF;
}
.select_row{
  color: #319CFF;
  background: #EFFAFF;
}
</style>
