import request from "../plugins/axios";

export function contactIndex(params) {
    return request({
        url: '/workContact/contactIndex',
        method: 'GET',
        params
    })
}
export function batchCreateContactTag(data) {
    return request({
        url: '/workContactTag/batchCreateContactTag',
        method: 'PUT',
        data
    })
}
export function workRoom(params) {
    return request({
        url: '/workRoom/index',
        method: 'GET',
        params
    })
}
