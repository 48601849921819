<template>
  <div @click="$refs.dropDownCase.closeOption()">
    <Popup
      v-model:show="followAdd"
      round
      position="bottom"
      closeable
      :close-on-click-overlay="false"
      :style="{ height: clientHeight+'px' }"
      @click="cancelInput">
      <div style="width: 100%;height: 100%;position: relative;">
        <div style="font-size: 14px;font-weight: bold;text-align: center;padding-top: 15px;">添加跟进</div>
        <Divider style="margin: 20px 0 5px 0;background: #FCFCFC;" />
        <div class="add-follow">
          <van-tabs v-model:active="activeTab" color="#1989FA" title-active-color="#1989FA" @change="changePage">
            <van-tab title="跟进记录" :name="1"></van-tab>
            <van-tab title="模板记录" :name="2"></van-tab>
          </van-tabs>
        </div>
        <div class="newly_row" v-if="activeTab==2">
          <div style="color: #999;">
            跟进记录：
            <span style="color: #1890FF;cursor: pointer;" @click.stop="newlyBtn">+ 新建</span>
            <input
              type="text"
              class="newly_input"
              placeholder="输入后回车"
              v-if="showAddInput"
              @click.stop
              @keyup.enter="addTemplate"
              v-model="templateName"
            >
          </div>
          <div>
            <div style="color: #999;margin-top: 15px;display: flex;">
              选择事件：
              <!--              下拉框-->
              <div style="margin-top: -5px;">
                <dropDownCase
                  ref="dropDownCase"
                  :optionData="templateData"
                  @change="tabSelect"/>
              </div>
              <span style="margin-left: 10px;cursor: pointer;" @click="deleteTemplate" v-if="selectTemplate!=-1">删除</span>
              <span style="margin-left: 10px;color:#2194FF;cursor: pointer;" v-if="selectTemplate!=-1" @click="selectCont">编辑</span>
            </div>
            <div style="color: #999;margin-top: 15px;" v-if="showModify">
              事件名称：
              <input type="text" class="input_style" v-model="modifyData.name" style="width: 150px;margin-left: -5px;" />
              <span style="margin-left: 10px;color:#2194FF;cursor: pointer;" @click="modifyTemplate">确定</span>
              <span style="margin-left: 10px;cursor: pointer;" @click="showModify=false">取消</span>
            </div>
            <div style="color: #999;">备注：</div>
          </div>
        </div>
        <div class="add_follow_page">
          <div class="text-box">
            <textarea
              class="textarea"
              placeholder="请输入要添加的跟进信息吧，添加成功后将出现在客户动态列表里~"
              v-model="content"
            ></textarea>
          </div>
        </div>
        <Divider style="margin:15px 0 0 0;background: #FCFCFC;" />
        <div class="foot_row">
          <van-button type="primary" block @click="addProcessEvent">保存</van-button>
        </div>
      </div>
    </Popup>
  </div>
</template>
<script>
import { Divider, Popup, Toast } from 'vant'
import dropDownCase from '@/components/dropDownCase/dropDownCase'
// eslint-disable-next-line no-unused-vars
import { ContactFollowApi } from '@/api/contactFollow'
export default {
  components: { Popup, Divider, Toast, dropDownCase },
  data () {
    return {
      followAdd: false,
      activeTab: 1,
      selectTemplate: -1,
      // addScoreBtn: false,
      showAddInput: false,
      templateName: '',
      // 修改数据
      modifyData: {
        id: '',
        name: ''
      },
      // 显示修改
      showModify: false,
      // 备注内容
      content: '',
      templateData: [],
      clientHeight: 320
    }
  },
  methods: {
    // 更改界面
    changePage () {
      if (this.activeTab == 1) {
        this.clientHeight = 320
      } else {
        this.clientHeight = 415
      }
    },
    // 获取跟进事件列表
    getEventList () {
      ContactFollowApi.templateIndex().then((res) => {
        this.templateData = res.data
        this.$forceUpdate()
        this.$nextTick(() => {
          this.$refs.dropDownCase.emptyOption()
        })
      })
    },
    // 切换选项
    tabSelect (e) {
      this.selectTemplate = e
      this.showModify = false
    },
    // 删除模板
    deleteTemplate () {
      ContactFollowApi.templateDelete({
        id: this.selectTemplate
      }).then((res) => {
        Toast('删除成功')
        this.getEventList()
        this.selectTemplate = -1
      })
    },
    addProcessEvent () {
    //  selectTemplate
      if (this.activeTab == 2) {
        if (this.selectTemplate == -1) {
          Toast('请选择事件')
          return false
        }
      }
      if (this.content == '') {
        Toast('备注不能为空')
        return false
      }
      ContactFollowApi.store({
        contactId: this.contactId,
        content: this.content,
        followTemplateId: this.selectTemplate
      }).then((res) => {
        Toast('添加成功')
        this.content = ''
        this.selectTemplate = -1
        this.followAdd = false
        this.$emit('change', true)
      })
    },
    show (contactId) {
      this.followAdd = true
      this.contactId = contactId
      this.getEventList()
    },
    // 选择内容
    selectCont () {
      this.templateData.forEach((item, index) => {
        if (item.id == this.selectTemplate) {
          this.modifyData = JSON.parse(JSON.stringify(item))
          this.showModify = true
        }
      })
    },
    // 修改模板
    modifyTemplate () {
      if (this.modifyData.name == '') {
        Toast('事件名称不能为空')
        return false
      }
      ContactFollowApi.templateUpdate({
        id: this.modifyData.id,
        name: this.modifyData.name,
        contactId: this.contactId
      }).then((res) => {
        Toast('修改成功')
        this.showModify = false
        this.selectTemplate = -1
        this.getEventList()
      })
    },
    // 添加模板
    addTemplate () {
      if (this.templateName == '') {
        Toast('事件名称不能为空')
        return false
      }
      ContactFollowApi.templateStore({
        name: this.templateName,
        contactId: this.contactId
      }).then((res) => {
        Toast('添加成功')
        this.getEventList()
        this.templateName = ''
        this.showAddInput = false
      })
    },
    newlyBtn () {
      this.showAddInput = true
    },
    cancelInput () {
      this.showAddInput = false
    }
  }
}
</script>
<style scoped lang="less">
.add-follow /deep/ .van-tab{
  flex: none;
  margin-left: 40px;
}
.add-follow /deep/ .van-tabs__wrap{
  height: 70px;
}
.text-box{
  display: flex;
  justify-content:center;
  margin-top: 20px;
  background: #FAFAFA;
  border: 1px solid #eee;
}
.textarea{
  background: none;
  outline: none;
  border: none;
  height: 200px;
  width: 100%;
  padding: 20px 30px 20px 30px;
  font-size: 26px;
  resize:none;
  color: #B2B2B2;
}
.add_follow_flex{
  display: flex;
  justify-content:space-between;
}
.add_follow_page{
  font-size: 26px;
  padding: 0 35px;
}
.newly_row{
  margin-top: 20px;
  font-size: 26px;
  padding: 0 50px 0 50px;
}
.foot_row{
  width: calc(100% - 80px);
  position: absolute;
  bottom: 25px;
  left: 40px;
}
.add_input{
  width: 100px;
  margin-left: 5px;
  margin-right: 5px;
  border: 1px solid #D9D9D9;
  height: 40px;
}
.newly_input{
  border: 1px solid #D9D9D9;
  margin-left: 15px;
  padding-left: 10px;
  height: 50px;
}
.input_style{
  padding-left: 10px;
  height: 50px;
  border: 1px solid #D9D9D9;
}
.option_row{
  width: 270px;
  height: 50px;
  border: 1px solid #cccccc;
  position: relative;
}
.option_row select{
  position: absolute;
  top: 0;
  /*清除select的边框样式*/
  border: none;
  /*清除select聚焦时候的边框颜色*/
  outline: none;
  /*将select的宽高等于div的宽高*/
  width: 100%;
  height: 50px;
}
.option_row option{
  height: 10px;
}
</style>
