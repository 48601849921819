<template>
  <div>
    <Popup
      v-model:show="personalTag"
      position="bottom"
      closeable
      round
      :close-on-click-overlay="false"
      :style="{ height: '490px' }"
      @click="cancelInput"
    >
      <div style="font-size: 14px;font-weight: bold;text-align: center;padding-top: 15px;">添加个人标签</div>
      <Divider />
      <div style="display: flex;">
        <van-button type="default" class="add_btn" @click.stop="showAddInput"><van-icon name="plus" />添加标签</van-button>
        <input
          type="text"
          class="add_input"
          placeholder="输入后回车"
          v-if="showInput"
          @click.stop
          @keyup.enter="addTemplate"
          v-model="tagName"
          style="padding-left: 5px;"
          ref="tagInput"
        />
      </div>
      <div class="show_tag_list" style="height: 315px;">
        <div style="display: flex;flex-wrap: wrap;">
          <div
            class="name-tags"
            v-for="(item,index) in listTags"
            :key="index"
            :class="[(addTagArray.indexOf(item)!=-1)?'selectTag':'']"
            @click="addTagsArray(item)"
          >{{ item.name }}
            <van-icon name="clear" class="close-icon" @click.stop="deleteTag(item)" />
          </div>
        </div>
      </div>
      <div class="foot_btn_row">
        <van-button type="info" block @click="clientAddTags">保存</van-button>
      </div>
    </Popup>
  </div>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import { ContactEmployeeTagApi } from '@/api/contactEmployeeTag'
// eslint-disable-next-line no-unused-vars
import { Popup, Divider, Toast, Dialog } from 'vant'
export default {
  components: {
    Popup, Divider
  },
  data () {
    return {
      personalTag: false,
      showInput: false,
      // 标签名称
      tagName: '',
      listTags: [],
      addTagArray: []
    }
  },
  methods: {
    show (contactId) {
      this.contactId = contactId
      this.personalTag = true
      this.getTabsList()
    },
    // 显示标签输入框
    showAddInput () {
      this.showInput = true
      this.$nextTick(() => {
        this.$refs.tagInput.focus()
      })
    },
    // 获取标签列表
    getTabsList () {
      ContactEmployeeTagApi.index({
        contactId: this.contactId
      }).then((res) => {
        this.listTags = res.data
        this.getSelectTabs()
      })
    },
    // 初始化标签数组
    getSelectTabs () {
      this.addTagArray = []
      this.listTags.forEach((item, index) => {
        if (item.isSelect == 1) {
          this.addTagArray.push(item)
        }
      })
    },
    addTagsArray (item) {
      const tagIndex = this.addTagArray.indexOf(item)
      if (tagIndex == -1) {
        this.addTagArray.push(item)
      } else {
        this.addTagArray.splice(tagIndex, 1)
      }
    },
    // 给客户添加标签
    clientAddTags () {
      if (this.addTagArray.length == 0) {
        Toast('请选择标签')
        return false
      }
      const tagIds = this.addTagArray.map((item, index) => {
        return item.id
      })
      ContactEmployeeTagApi.employeeTagStore({
        contactId: this.contactId,
        tagIds
      }).then((res) => {
        Toast('设置成功')
        this.personalTag = false
        this.$emit('change', true)
      })
    },
    // 添加标签
    addTemplate () {
      ContactEmployeeTagApi.store({
        name: this.tagName
      }).then((res) => {
        Toast('添加成功')
        this.getTabsList()
        this.tagName = ''
        this.showInput = false
      })
    },
    // 删除标签
    deleteTag (item) {
      Dialog.confirm({
        message: '是否删除该标签'
      }).then(() => {
        ContactEmployeeTagApi.destroy({
          id: item.id
        }).then((res) => {
          Toast('删除成功')
          this.getTabsList()
        })
      }).catch(() => {
      })
    },
    cancelInput () {
      this.showInput = false
    }
  }
}
</script>
<style scoped lang="less">
.add_btn{
  border: 1px dashed #2C9AFF;
  height: 25px;
  font-size: 12px;
  color: #2C9AFF;
  padding: 0 5px;
  margin-left: 15px;
}
.add_input{
  width: 75px;
  height: 25px;
  margin-left: 10px;
  border: 1px solid #CDE5F9;
  font-size: 14px;
}
.show_tag_list{
  overflow-y: scroll;
}
.foot_btn_row{
  margin: 25px auto 0;
  width: calc(100% - 50px);
}
.name-tags{
  border: 1px solid #CDE5F9;
  background: #F7F7F7;
  padding: 5px 10px;
  border-radius: 4px;
  margin-top: 15px;
  margin-left: 12.5px;
  font-size: 14px;
  height: 30px;
  position: relative;
  cursor: pointer;
  box-sizing: content-box;
}
.selectTag{
  background: #45A5FE;
  color: #fff;
  border: 1px solid #45A5FE;
}
.close-icon{
  font-size: 14px;
  margin-left: 2.5px;
  position: absolute;
  top: -7.5px;
  right: -4px;
  z-index: 100;
  color: #7E99B0;
}
.selectTag .close-icon{
  color: #90B9DE;
}
</style>
