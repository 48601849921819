<template>
  <div class="index">
    <div class="content" style="overflow:hidden;">
      <van-tabs v-model="active" style="margin-top: 10px;" color="#1890ff" title-active-color="#1890ff">
        <van-tab name="1" title="客户统计">
          <div class="basecard">
            <div class="header">
              <p class="header__title">客户统计</p>
              <div class="header__right">
                <img
                    src="../../assets/images/customer-tips.svg"
                    class="fs14 svg-icon"

                    style="margin-left: 2.5px; width: 14px; height: 14px;margin-top: 2px;">
                <div class="switch">
                  <p @click="customer(false)" :class="{time: !activeDate}" class="switch__item switch__item--active">
                    近7日
                  </p>
                  <p @click="customer(true)" :class="{time: activeDate}" class="switch__item">近30天</p>
                </div>
              </div>
            </div>
            <div class="databox">
              <div class="list customer van-hairline--surround">
                <div class="list__item mgb40"><p class="list__item__count">{{ statistics.total }}</p>
                  <p class="list__item__desc">总人数</p></div>
                <div class="list__item mgb40"><p class="list__item__count">{{ statistics.add }}</p>
                  <p class="list__item__desc">新增</p></div>
                <div class="list__item mgb40"><p class="list__item__count">{{ statistics.loss }}</p>
                  <p class="list__item__desc">流失</p></div>
                <div class="list__item"><p class="list__item__count">{{ statistics.totalDistinct }}</p>
                  <p class="list__item__desc align__center">总人数(去重)</p></div>
                <div class="list__item"><p class="list__item__count">{{ statistics.addDistinct }}</p>
                  <p class="list__item__desc">新增(去重)</p></div>
                <div class="list__item"><p class="list__item__count">{{ statistics.lossDistinct }}</p>
                  <p class="list__item__desc">流失(去重)</p></div>
              </div>
            </div>
            <div class="chart__box"><!----><!---->
              <p class="charts__tips">
                数据来源：企业微信API
              </p>
              <div class="Echarts" style='padding-left: 12px;'>
                <div id="myChartCategory" :style="{ height: '300px'}"></div>
              </div>
            </div>
          </div>
          <div class="basecard mgt16">
            <div class="header"><p class="header__title">员工排行</p>
              <div class="header__right">
                <img
                    src="../../assets/images/customer-tips.svg"
                    class="fs14 svg-icon"

                    style="margin-left: 2.5px; width: 14px; height: 14px;margin-top: 2px;">
                <div class="switch"><p
                    class="switch__item switch__item--active">
                </p>
                  <p class="switch__item"></p></div>
              </div>
            </div>
            <div class="content">
              <div class="rank">
                <div class="header"><p class="header__title fs17">昨日新增客户数</p>
                  <p class="header__time fs14 van-hairline--left">{{yesterday}}</p></div>
                <div class="yesterdayAdd" v-if="employeeRanking.length > 0">
                  <div v-for="(item,index) in employeeRanking" :key="index">
                    <p>{{index + 1}} {{ item.name }}</p>
                    <span>{{item.total}}</span>
                  </div>
                </div>
                <van-empty
                    v-else
                    class="custom-image"
                    :image="require('../../assets/images/emptyimg.png')"
                    description="暂无数据"
                />
              </div>
            </div>
            <div>
            </div>
          </div>
          <div class="basecard mgt16">
            <div class="header"><p class="header__title">客户来源</p>
              <div class="header__right">
                <img
                    src="../../assets/images/customer-tips.svg"
                    class="fs14 svg-icon"

                    style="margin-left: 2.5px; width: 14px; height: 14px;margin-top: 2px;">
                <div class="switch"><p
                    class="switch__item switch__item--active">
                </p>
                  <p class="switch__item"></p></div>
              </div>
            </div>
            <div class="content">
              <div class="rank">
                <div class="Echarts" style="margin-top: -17px;position: relative">
                  <div id="Category" :style="{width: '343px', height: '300px'}"></div>
                  <van-empty
                      v-if="pieDatas.length <= 0"
                      style="position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%);
    width: 100%;"
                      class="custom-image"
                      :image="require('../../assets/images/emptyimg.png')"
                      description="暂无数据"
                  />
                </div>
              </div>
            </div>
            <div>
            </div>
          </div>
        </van-tab>

        <van-tab name="2" title="客户群统计">
          <div class="basecard">
            <div class="header">
              <p class="header__title">客户群统计</p>
              <div class="header__right" style="flex: none;">
                <img
                    src="../../assets/images/customer-tips.svg"
                    class="fs14 svg-icon"

                    style="margin-left: 2.5px; width: 14px; height: 14px;">
                <div class="switch">
                  <p @click="customerBase(false)" :class="{ time: !roomDate }" class="switch__item switch__item--active">
                  近7日
                  </p>
                  <p @click="customerBase(true)" :class="{ time: roomDate }" class="switch__item">近30天</p></div>
              </div>
            </div>
            <div class="databox">
              <div style="padding-top: 15px;padding-bottom: 15px;" class="list customer van-hairline--surround">
                <div style="width: 50%;" class="list__item mgb40">
                  <p class="list__item__count">{{roomStatistics.total}}</p>
                  <p class="list__item__desc">客户群总数</p></div>
                <div style="width: 50%;" class="list__item mgb40">
                  <p class="list__item__count">{{roomStatistics.totalContact}}</p>
                  <p class="list__item__desc">群总人数</p>
                </div>
                <div style="width: 50%;" class="list__item mgb40">
                  <p class="list__item__count">{{roomStatistics.addContact}}</p>
                  <p class="list__item__desc">新入群客户数</p></div>
                <div style="width: 50%;       margin-bottom: 20px;" class="list__item">
                  <p class="list__item__count">{{roomStatistics.lossContact}}</p>
                  <p class="list__item__desc align__center">退群客户数</p></div>
              </div>
            </div>
            <div class="chart__box">
              <p class="charts__tips">
              数据来源：企业微信API
              </p>
              <div class="Echarts" style='padding-left: 12px;'>
                <div id="myChart" :style="{ height: '300px'}"></div>
              </div>
            </div>
          </div>
          <div class="basecard mgt16">
            <div class="header"><p class="header__title">员工排行</p></div>
            <div class="content">
              <div class="rank">
                <div class="header"><p class="header__title fs17">昨日入群客户数</p>
                  <p class="header__time fs14 van-hairline--left">{{yesterday}}</p></div>
                <div class="yesterdayAdd" v-if="employee.length > 0">
                  <div v-for="(item,index) in employeeRanking" :key="index">
                    <p>{{index + 1}} {{ item.name }}</p>
                    <span>{{item.total}}</span>
                  </div>
                </div>
                <van-empty
                    v-else
                    class="custom-image"
                    :image="require('../../assets/images/emptyimg.png')"
                    description="暂无数据"
                />
              </div>
            </div>
            <div>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import {workEmployee,roomStatistics} from '../../api/pullNew'

export default {
  data() {
    return {
      // 文字
      message: '',
      // tbas切换
      tabsChose: 0,
      active: '1',
      seven: this.dateFormat(new Date() - (86400 * 7 * 1000)),
      thirty: this.dateFormat(new Date() - (86400 * 30 * 1000)),
      // dayStatistics
      dayStatistics: {
        date: [],
        add: [],
        loss: []
      },
      statistics: {},
      activeDate: false,
      roomDate: false,
      employeeRanking: [],
      employee:[],
      roomStatistics: {},
      chartCategory: {
        date: [],
        add: [],
        loss: [],
        eoss: []
      },
      yesterday: this.dateFormat(new Date() - (86400 * 1 * 1000)),
      // 饼图
      pieDatas:[],
    }
  },
  watch: {
    active(e){
      const that = this
      if(e == '2'){
        setTimeout(function () {
          that.categoryCategory()
        },1)
      }
    }
  },
  methods: {
    onClickLeft() {
      this.$router.back()
    },
    // 图表数据
    Category() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('myChartCategory'))
      // 绘制图表
      myChart.setOption({
        xAxis: {
          type: 'category',
          data: this.dayStatistics.date
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: this.dayStatistics.add,
            type: 'line',
            smooth: true
          },
          {
            data: this.dayStatistics.loss,
            type: 'line',
            smooth: true
          }
        ]
      });
    },
    Category2() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('Category'))
      // 绘制图表
      let index = 0;
      const datas = this.pieDatas
      const colorList = ['#545d93', '#8543E0', '#f4c170', '#6189f2', '#29d0d2', '#ed4981']
      myChart.setOption({
        title: {
          x: 'center',
          y: 'center',
          textStyle: {
            fontSize: 12
          }
        },
        legend: {
          left: 40,
          bottom: 0,
          right: 40,
          itemWidth: 7,
          itemHeight: 7,
          itemGap: 20,
          borderRadius: 4,
          textStyle: {
            color: '#262C41',
            fontSize: 10
          },
          data: datas
        },
        tooltip: {
          trigger: 'item'
        },
        series: [{
          type: 'pie',
          center: ['50%', '50%'],
          radius: ['30%', '45%'],
          clockwise: true,
          avoidLabelOverlap: true,

          itemStyle: {
            normal: {
              color: function (params) {
                return colorList[params.dataIndex]
              }
            }
          },
          label: {
            show: true,
            position: 'outside',
            formatter: '{d}%',
            rich: {
              hr: {
                backgroundColor: 't',
                borderRadius: 3,
                width: 3,
                height: 3,
                padding: [3, 3, 0, -12]
              },
              a: {
                padding: [-30, 15, -20, 15]
              }
            }
          },
          labelLine: {
            normal: {
              length: 20,
              length2: 30,
              lineStyle: {
                width: 1
              }
            }
          },
          data: datas,
        }],
      })
    },
    categoryCategory() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('myChart'))
      // 绘制图表
      myChart.setOption({
        xAxis: {
          type: 'category',
          data: this.chartCategory.date
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: this.chartCategory.add,
            type: 'line',
            smooth: true
          },
          {
            data: this.chartCategory.loss,
            type: 'line',
            smooth: true
          },
          {
            data: this.chartCategory.eoss,
            type: 'line',
            smooth: true
          }
        ]
      });
    },
    // 日期格式化
    dateFormat(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + '-';
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
      return Y + M + D
    },
    // 获取数据
    customer(e) {
      this.activeDate = e
      workEmployee({
        day: this.activeDate ? this.thirty : this.seven
      }).then(res => {
        this.dayStatistics = {
          date: [],
          add: [],
          loss: []
        }
        res.data.dayStatistics.map(item => {
          this.dayStatistics.date.push(item.day)
          this.dayStatistics.add.push(item.add)
          this.dayStatistics.loss.push(item.loss)
        })
        this.pieDatas = res.data.contactSource.map(item=>{
          return {
            name: item.addWayName,
            value: item.count
          }
        })
        this.Category2()

        this.Category()
        this.statistics = res.data.statistics
        this.employeeRanking = res.data.employeeRanking

      })
    },
    customerBase(e) {
      this.roomDate = e
      roomStatistics({day:this.roomDate ? this.thirty : this.seven}).then(res =>{
        this.roomStatistics = res.data.statistics
        this.chartCategory = {
          date: [],
          add: [],
          loss: [],
          eoss: []
        }
        res.data.dayStatistics.map(item => {
          this.chartCategory.date.push(item.day)
          this.chartCategory.add.push(item.addContact)
          this.chartCategory.loss.push(item.addRoom)
          this.chartCategory.eoss.push(item.lossContact)
        })
        if(this.active == '2'){
          this.categoryCategory()
        }
        this.employee = res.data.employeeRanking
      })
    }
  },
  mounted() {
    var that = this
    setTimeout(function () {
      that.customer()
      that.customerBase()
    }, 1)
  },
}
</script>

<style lang="less" scoped>
/deep/ .van-tabs__line {
  bottom: 9px;
}

/deep/ .van-tabs__nav--line {
  padding-bottom: 8px;
}

/deep/ .van-tab__text {
  margin-bottom: 10px;
}

/deep/ .van-tab {
  background: #ffffff;
}

/deep/ .van-tabs__nav {
  background: #F1f2f5;
}

/deep/ .van-empty__image img {
  object-fit: contain;
}

.index {
  font-size: 14px;
  height: 100%;
  background: #fff;

  p {
    margin: 0 !important;
  }

  .fs17 {
    font-size: 17px;
  }

  .fs14 {
    font-size: .14px;
  }

  .content {
    background: #fff;
    min-height: 92%;
    padding-top: 5px;

    .time {
      font-weight: bolder !important;
      color: #444 !important;
    }

    img {
      width: 22px;
      height: 22px;
    }

    .mgt16 {
      margin-top: 8px;
    }

    .basecard {
      background-color: #fff;
      padding: 0 0 24px 0;

      .header {
        height: 65px;
        padding: 0 15px 0 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .header__title {
          line-height: 21px;
          font-weight: 500;
          color: #000;
        }

        .header__right {
          flex: 1;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .svg-icon {
            vertical-align: -0.15em;
            fill: currentColor;
            overflow: hidden
          }

          .switch {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .switch__item--active {
              font-weight: 500;
              color: #000;
            }

            .switch__item {

              padding: 0 3px;
              margin-left: 7px;
              color: #8c8c8c;
              font-size: 12px;
            }
          }
        }

      }

      .databox {
        padding: 0 16px;

        .list {
          border-radius: 15px;
          padding: 36px 0;
          overflow: hidden;
          display: flex;
          justify-content: space-around;
          align-items: center;
          flex-wrap: wrap;
          padding-top: 30px;
          padding-bottom: 30px;

          .list__item__desc {
            font-size: 11px;
            line-height: 16px;
            color: #545454;
          }

          .list__item__count {
            font-size: 20px;
            line-height: 21px;
            margin-bottom: 4px;
            font-weight: 500;
            color: #000;
            text-shadow: 0 3px 6px rgb(0 0 0 / 16%);
          }

          .mgb40 {
            margin-bottom: 20px;
          }

          .list__item {
            width: 30%;
            text-align: center;
          }
        }

        .customer {

        }
      }

      .chart__box {
        position: relative;
        height: 250px;

        .charts__tips {
          font-size: 10px;
          padding: 5px 16px 0;
          text-align: right;
          color: #b2b2b2;
        }
      }

      .content {
        padding: 0 16px;
        background-color: #fff;

        .rank {
          .header {
            position: relative;
            padding: 18px 28px 18px 26px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            line-height: 24px;
            font-weight: 500;
            border-radius: 15px 15px 0 0;
            color: #fff;
            background-color: #294ba3;

            &:after {
              content: " ";
              width: 0;
              height: 0;
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              border-bottom: 8px solid #fff;
              position: absolute;
              bottom: 0;
              left: 79px;
            }

            .header__title {
              line-height: 0.56rem;
              font-weight: 500;
              color: #fff;
            }

            .header__time {
              padding-left: 32px;
              position: relative;

              &:after {
                border-left-width: 1px !important;
                position: absolute;
                box-sizing: border-box;
                content: " ";
                pointer-events: none;
                top: -50%;
                right: -50%;
                bottom: -50%;
                left: -50%;
                border: 0 solid #f2f4f8;
                -webkit-transform: scale(.5) !important;
                transform: scale(.5);
              }
            }
          }
        }
      }
      .yesterdayAdd{
        padding: 13px 15px;
        min-height: 120px;
        border: 1px solid #f2f1f1;
        border-radius: 0 0 16px 16px;
        color: #000;
        font-size: 14px;
        div{
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
  }
}
</style>
