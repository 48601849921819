<template>
  <div>
    <Popup
      v-model:show="selectPopup"
      position="bottom"
      closeable
      round
      :close-on-click-overlay="false">
      <div style="font-size: 14px;font-weight: bold;text-align: center;padding-top: 15px;">模板拉群</div>
      <Divider />
      <div class="select_page">
        <div style="color: #878787;font-size: 14px;">选择客户({{ selectEmployee.length }}) 当前可选择{{ customerData.length-selectEmployee.length }}位客户</div>
        <van-search
          placeholder="请输入搜索关键词"
          @search="searchInput"
          @input="emptyInput"
          v-model="employeeName"
          :clearable="false"
        />
        <div class="customer_list" style="height: 280px;">
          <van-list
            :finished="finished"
            finished-text="没有更多了"
          >
            <van-cell v-for="(item,index) in customerData" :key="index">
              <div
                class="select_flex"
                @click="checkEmployee(item)"
                style="cursor: pointer;"
                :class="[item.wxExternalUserid==wxExternalUserid?'notclick':'']">
                <div style="display: flex;">
                  <img :src="item.avatar" alt="" style="width: 40px;height: 40px;">
                  <div style="margin-left: 10px;margin-top: 10px;">{{ item.name }}</div>
                </div>
                <van-checkbox v-model="item.checked"></van-checkbox>
              </div>
            </van-cell>
          </van-list>
        </div>
        <van-button type="primary" block style="margin-top: 10px;" @click="selectClientBtn">确定</van-button>
      </div>
    </Popup>
  </div>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import { ContactApi } from '@/api/contact'
import { Popup, Divider } from 'vant'
export default {
  components: {
    Popup, Divider
  },
  data () {
    return {
      selectPopup: false,
      finished: true,
      customerData: [],
      selectEmployee: [],
      wxExternalUserid: '',
      employeeName: ''
    }
  },
  methods: {
    searchInput () {
      this.getEmployeeList()
    },
    emptyInput () {
      if (this.employeeName == '') {
        this.getEmployeeList()
      }
    },
    show (customerArr, wxExternalUserid) {
      this.selectPopup = true
      this.wxExternalUserid = wxExternalUserid
      this.selectEmployee = customerArr.map((item, index) => {
        item.checked = true
        return JSON.stringify(item)
      })
      this.getEmployeeList()
    },
    // 获取员工列表
    getEmployeeList () {
      ContactApi.employeeContact({
        name: this.employeeName
      }).then((res) => {
        this.handleUserData(res.data)
      })
    },
    // 处理数据
    handleUserData (data) {
      data.forEach((item, index) => {
        item.checked = true
        const dataIndex = this.selectEmployee.indexOf(JSON.stringify(item))
        if (dataIndex == -1) {
          item.checked = false
        }
      })
      this.customerData = data
    },
    // 确定按钮
    selectClientBtn () {
      this.$emit('change', this.selectEmployee)
      this.selectPopup = false
    },
    // 选择成员
    checkEmployee (item) {
      const selectData = JSON.parse(JSON.stringify(item))
      selectData.checked = true
      const indexArr = this.selectEmployee.indexOf(JSON.stringify(selectData))
      if (indexArr == -1) {
        item.checked = true
        this.selectEmployee.push(JSON.stringify(selectData))
      } else {
        item.checked = false
        this.selectEmployee.splice(indexArr, 1)
      }
    }
  }
}
</script>
<style scoped lang="less">
.notclick {
  pointer-events: none;
}
.select_flex{
  display: flex;
  justify-content:space-between;
}
.select_page{
  padding: 0 35px 35px 35px;
  font-size: 28px;
  color: #333;
}
.checkbox:checked {
  background: #1673ff;
  border: solid 1px #1673ff;
}
.checkbox {
  width: 0.5rem;
  height: 0.5rem;
  background-color: #ffffff;
  border: solid 1px #dddddd;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  font-size: 0.8rem;
  margin: 15px 0 0 0;
  padding: 0;
  position: relative;
  display: inline-block;
  vertical-align: top;
  cursor: default;
  -webkit-appearance: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-transition: background-color ease 0.6s;
  transition: background-color ease 0.6s;
}
.checkbox:checked::after {
  content: '';
  top: 0.1rem;
  left: 0.09rem;
  position: absolute;
  background: transparent;
  border: #fff solid 2px;
  border-top: none;
  border-right: none;
  height: 0.12rem;
  width: 0.25rem;
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.customer_list{
  overflow-y: scroll;
}
</style>
