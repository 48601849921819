<template>
  <div>
    <Popup v-model:show="showEmployeePop" :style="{ width:'80%',top:'30%' }">
      <div class="news-page">
        <div style="display: flex;">
          <img :src="employeeInfo.avatar" alt="" style="width: 50px;height: 50px;">
          <div style="margin-left: 10px;margin-top: 5px;display: flex;">
            <span style="font-weight: bold;">{{ employeeInfo.employeeName }}</span>
            <img src="../../../assets/personalIcon.png" alt="" style="width: 20px;height: 20px;margin-left: 5px;margin-top: -1px;">
          </div>
        </div>
        <Divider />
        <div class="news-row">
          <div style="color: #999;">部门</div>
          <div style="padding-left: 15px;">{{ employeeInfo.corpName }}</div>
        </div>
        <van-button type="primary" block class="send-btn" @click="sendBtn">发消息</van-button>
      </div>
    </Popup>
  </div>
</template>

<script>
import { Popup, Divider } from 'vant'
// eslint-disable-next-line no-unused-vars
import { openEnterpriseChat } from '@/utils/wxCodeAuth'
export default {
  components: {
    Popup, Divider
  },
  data () {
    return {
      showEmployeePop: false,
      employeeInfo: {}
    }
  },
  methods: {
    show (employeeInfo) {
      this.showEmployeePop = true
      this.employeeInfo = employeeInfo
    },
    // 发送消息
    async sendBtn () {
      const userIds = this.employeeInfo.wxUserId
      await openEnterpriseChat(userIds)
    }
  }
}
</script>
<style scoped lang="less">
.news-page{
  padding: 35px 35px 25px 35px;
  font-size: 14px;
}
.news-flex{
  display: flex;
  justify-content:space-between;
}
.news-row{
  display: flex;
}
.send-btn{
  margin-top: 30px;
  height: 40px;
}
</style>
