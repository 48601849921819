<template>
  <div>
    <Popup
      v-model:show="showModifyScore"
      position="bottom"
      closeable
      :close-on-click-overlay="false"
      :style="{ height: '45%' }">
      <div style="font-size: 18px;font-weight: bold;text-align: center;padding-top: 15px;">修改评分</div>
      <Divider />
      <div style="display: flex;" class="modify_page">
        给客户
        <div style="margin-left: 5px;margin-top: -5px;" class="option_row">
          <select>
            <option>添加</option>
            <option>减少</option>
          </select>
        </div>
        <input type="number" v-model="scoreNum" class="input_style">分
      </div>
      <div style="display: flex;justify-content:center;margin-top: 15px;">
        <textarea class="textarea" placeholder="请输入修改评分备注"></textarea>
      </div>
    </Popup>
  </div>
</template>
<script>
import { Popup, Divider } from 'vant'
export default {
  components: {
    Popup, Divider
  },
  data () {
    return {
      showModifyScore: false,
      scoreNum: ''
    }
  },
  methods: {
    show () {
      this.showModifyScore = true
    }
  }
}
</script>
<style scoped lang="less">
.textarea{
  border: 1px solid #E8E8E8;
  height: 200px;
  width: calc(100% - 130px);
  padding: 20px 30px 20px 30px;
  font-size: 27px;
  resize:none;
}
.modify_page{
  font-size: 28px;
  padding: 0 35px 0 35px;
}
.option_row{
  width: 150px;
  height: 50px;
  border: 1px solid #cccccc;
  position: relative;
}
.option_row select{
  position: absolute;
  top: 0;
  /*清除select的边框样式*/
  border: none;
  /*清除select聚焦时候的边框颜色*/
  outline: none;
  /*将select的宽高等于div的宽高*/
  width: 100%;
  height: 50px;
}
.option_row option{
  height: 10px;
}
.input_style{
  width: 100px;
  height: 45px;
  border: 1px solid #cccccc;
  margin-top: -9px;
  margin-left: 10px;
  margin-right: 10px;
}
</style>
