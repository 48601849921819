<template>
  <div style="">
    <Popup
      v-model:show="addNeed"
      position="bottom"
      closeable
      round
      :close-on-click-overlay="false"
      :style="{ background:'#F2F1F5' }">
      <div style="font-size: 14px;font-weight: bold;text-align: center;padding-top: 15px;">添加待办提醒</div>
      <Divider />
      <!--      -->
      <div class="add-todo">
        <van-tabs
          v-model:active="activeTab"
          type="card"
          title-active-color="#fff"
          title-inactive-color="#595959"
          color="#1890FF">
          <van-tab title="定时提醒" :name="1">
            <div class="add_need_page" style="margin-top: 15px;padding-bottom: 15px;">
              <div style="margin-top: 10px;">
                <div class="select_time" v-for="(item,index) in setDate" :key="index">
                  <div class="tip_cont">
                    <van-field
                      v-model="item.content"
                      rows="2"
                      type="textarea"
                      maxlength="100"
                      placeholder="请输入要添加的提醒事件，添加成功后将会在设定好的时间提醒你哦~"
                      show-word-limit/>
                  </div>
                  <van-list style="margin-top: 10px;">
                    <van-cell style="width: 100%;padding: 10px;">
                      <div class="add_need_flex" @click="choiceTime(index)">
                        <div class="tip_row">
                          <img src="../../../assets/calendarImg.png" alt="" class="tip-icon">提醒日期：</div>
                        <div class="select_time_style">
                          <div v-if="item.day">{{ item.day }}</div>
                          <div v-else>提醒日期</div>
                          <div><van-icon name="arrow" /></div>
                        </div>
                      </div>
                    </van-cell>
                    <van-cell style="width: 100%;padding: 10px;">
                      <div class="add_need_flex" @click="choiceDate(index)">
                        <div class="tip_row"><img src="../../../assets/alarmImg.png" alt="" class="tip-icon">提醒时间：</div>
                        <div class="select_time_style">
                          <div v-if="item.time">{{ item.time }}</div>
                          <div v-else>提醒时间</div>
                          <div><van-icon name="arrow" /></div>
                        </div>
                      </div>
                    </van-cell>
                    <van-cell style="width: 100%;padding: 10px;" v-if="index!=0">
                      <div style="text-align: center;cursor: pointer;">
                        <span @click="delTipBtn(index)">删除</span>
                      </div>
                    </van-cell>
                  </van-list>
                </div>
              </div>
              <div style="text-align: center;margin-top: 15px;">
                <span style="color: #1890FF;cursor: pointer;" @click="addTipDate"><van-icon name="add"/>添加提醒</span>
              </div>
            </div>
          </van-tab>
          <van-tab title="周期提醒" :name="2">
            <div class="add_need_page" style="margin-bottom: 20px;">
              <div class="select_time">
                <div class="tip_cont">
                  <van-field
                    v-model="cycleTipData.content"
                    rows="2"
                    type="textarea"
                    maxlength="100"
                    placeholder="请输入要添加的提醒事件，添加成功后将会在设定好的时间提醒你哦~"
                    show-word-limit/>
                </div>
                <van-list style="margin-top: 10px;">
                  <van-cell style="width: 100%;padding: 10px;">
                    <div class="add_need_flex" @click="$refs.selectTime.setTipsDate()">
                      <div class="tip_row"><img src="../../../assets/calendarImg.png" alt="" class="tip-icon">提醒日期：</div>
                      <div class="select_time_style">
                        <div v-if="cycleTipData.day">{{ cycleTipData.day }}</div>
                        <div v-else>提醒日期</div>
                        <div><van-icon name="arrow" /></div>
                      </div>
                    </div>
                  </van-cell>
                  <van-cell style="width: 100%;padding: 10px;">
                    <div class="add_need_flex" @click="$refs.selectDate.show()">
                      <div class="tip_row"><img src="../../../assets/alarmImg.png" alt="" class="tip-icon">提醒时间：</div>
                      <div class="select_time_style">
                        <div v-if="cycleTipData.time">{{ cycleTipData.time }}</div>
                        <div v-else>提醒时间</div>
                        <div><van-icon name="arrow" /></div>
                      </div>
                    </div>
                  </van-cell>
                </van-list>
              </div>
              <div class="select_time">
                <van-list style="margin-top: 10px;">
                  <van-cell style="width: 100%;padding: 10px;">
                    <div class="add_need_flex" @click="$refs.setRepeat.show()">
                      <div class="tip_row"><img src="../../../assets/repeatImg.png" alt="" class="tip-icon">重复：</div>
                      <div class="select_time_style">
                        <div style="font-size: 12px;">
                          <span v-if="cycleTipData.repeat.repeatType==1">每天</span>
                          <span v-if="cycleTipData.repeat.repeatType==2">每周</span>
                          <span v-if="cycleTipData.repeat.repeatType==3">每月</span>
                          <span v-if="cycleTipData.repeat.repeatType==4">自定义重复</span>
                        </div>
                        <div><van-icon name="arrow" /></div>
                      </div>
                    </div>
                  </van-cell>
                  <van-cell style="width: 100%;padding: 10px;">
                    <div style="color: #2797FF;">
                      日程将于
                      <span v-if="cycleTipData.repeat.repeatType==1">每天</span>
                      <span v-if="cycleTipData.repeat.repeatType==2">每周周一</span>
                      <span v-if="cycleTipData.repeat.repeatType==3">每月1号</span>
                      <template v-if="cycleTipData.repeat.repeatType==4">
                        <span v-if="cycleTipData.repeat.rateType==1">每{{ cycleTipData.repeat.rateNum }}天</span>
                        <span v-if="cycleTipData.repeat.rateType==2">
                          每{{ cycleTipData.repeat.rateNum }}周的
                          <span v-for="(item,index) in cycleTipData.repeat.dayNum" :key="index" style="margin-left: 5px;">
                            <template v-if="item==0">周日</template>
                            <template v-if="item==1">周一</template>
                            <template v-if="item==2">周二</template>
                            <template v-if="item==3">周三</template>
                            <template v-if="item==4">周四</template>
                            <template v-if="item==5">周五</template>
                            <template v-if="item==6">周六</template>
                          </span>
                        </span>
                        <span v-if="cycleTipData.repeat.rateType==3">
                          每{{ cycleTipData.repeat.rateNum }}个月的
                          <span v-for="(item,index) in cycleTipData.repeat.dayNum" :key="index" >[{{ item }}]</span>
                        </span>
                      </template>
                      重复</div>
                  </van-cell>
                  <van-cell style="width: 100%;padding: 10px;">
                    <div class="add_need_flex" @click="$refs.endRepeat.show()">
                      <div class="tip_row"><img src="../../../assets/closeloopimg.png" alt="" class="tip-icon">结束重复：</div>
                      <div class="select_time_style">
                        <div>
                          <span v-if="cycleTipData.endRepeat.endRepeatType==1">一直重复</span>
                          <span v-if="cycleTipData.endRepeat.endRepeatType==2">重复{{ cycleTipData.endRepeat.repeatNum }}次</span>
                          <span v-if="cycleTipData.endRepeat.endRepeatType==3">{{ cycleTipData.endRepeat.endDay }} {{ cycleTipData.endRepeat.endTime }}</span>
                        </div>
                        <div><van-icon name="arrow" /></div>
                      </div>
                    </div>
                  </van-cell>
                </van-list>
              </div>
            </div>
          </van-tab>
        </van-tabs>
      </div>
      <!--      -->
      <div class="add_need_page" style="margin-bottom: 10px;">
        <van-button type="primary" block @click="saveTipsCont">保存</van-button>
      </div>
    </Popup>
    <!--    设置时间-->
    <selectDate ref="selectDate" @change="receiveTime" />
    <!--    选择日期-->
    <selectTime ref="selectTime" @change="receiveDate" />
    <!--    设置重复 -->
    <setRepeat ref="setRepeat" @change="receiveRepeatData" />
    <!--    结束重复-->
    <endRepeat ref="endRepeat" @change="(e)=>cycleTipData.endRepeat=e" />
  </div>
</template>
<script>
import { Popup, Divider, NoticeBar, Toast } from 'vant'
// eslint-disable-next-line no-unused-vars
import { ContactTodoApi } from '@/api/contactTodo'
import selectTime from './contactTodo/selectTime'
import setRepeat from './contactTodo/setRepeat'
import endRepeat from './contactTodo/endRepeat'
import selectDate from './contactTodo/selectDate'
export default {
  components: {
    Popup, Divider, NoticeBar, selectTime, setRepeat, endRepeat, Toast, selectDate
  },
  data () {
    return {
      addNeed: false,
      activeTab: 1,
      currentDate: '',
      setDate: [
        {
          content: '',
          day: '',
          time: ''
        }
      ],
      dateIndex: '',
      timeIndex: '',
      cycleTipData: {
        content: '',
        day: '',
        time: '',
        // 重复规则
        repeat: {
          repeatType: 1,
          rateType: '',
          rateNum: '',
          dayNum: ''
        },
        // 结束规则
        endRepeat: {
          endRepeatType: '',
          repeatNum: '',
          endDay: '',
          endTime: ''
        }
      }
    }
  },
  methods: {
    // 设置重复
    receiveRepeatData (e) {
      this.cycleTipData.repeat = e
    },
    // 保存
    saveTipsCont () {
      // 定时提醒
      if (this.activeTab == 1) {
        for (let i = 0; i < this.setDate.length; i++) {
          if (this.setDate[i].content == '') {
            Toast('待办提醒' + (i + 1) + '的提醒事件不能为空')
            return false
          }
          if (this.setDate[i].day == '') {
            Toast('请设置待办提醒' + (i + 1) + '的提醒日期')
            return false
          }
          if (this.setDate[i].time == '') {
            Toast('请设置待办提醒' + (i + 1) + '的提醒时间')
            return false
          }
        }
      }
      if (this.activeTab == 2) {
        if (this.cycleTipData.day == '') {
          Toast('请选择提醒日期')
          return false
        }
        if (this.cycleTipData.time == '') {
          Toast('请选择提醒时间')
          return false
        }
        if (this.cycleTipData.content == '') {
          Toast('提醒事件不能为空')
          return false
        }
      }
      //  周期提醒
      const params = {
        contactId: this.contactId,
        type: this.activeTab
      }
      if (this.activeTab == 1) {
        params.timingRemind = this.setDate
      } else {
        params.cycleRemind = this.cycleTipData
      }
      ContactTodoApi.store(params).then((res) => {
        Toast('添加成功')
        this.$emit('change', true)
        this.addNeed = false
      })
    },
    // 删除按钮
    delTipBtn (index) {
      this.setDate.splice(index, 1)
    },
    // 添加提醒时间
    addTipDate () {
      const date = {
        content: '',
        day: '',
        time: ''
      }
      this.setDate.push(date)
    },
    // 接收日期
    receiveDate (e) {
      if (this.activeTab == 1) {
        this.setDate[this.timeIndex].day = e
      } else if (this.activeTab == 2) {
        this.cycleTipData.day = e
      }
    },
    // 接收时间
    receiveTime (e) {
      (e)
      if (this.activeTab == 1) {
        this.setDate[this.dateIndex].time = e
      } else if (this.activeTab == 2) {
        this.cycleTipData.time = e
      }
    },
    // 选择日期
    choiceTime (index) {
      this.timeIndex = index
      this.$refs.selectTime.setTipsDate()
    },
    // 选择时间
    choiceDate (index) {
      this.dateIndex = index
      this.$refs.selectDate.show()
    },
    show (contactId) {
      this.contactId = contactId
      this.addNeed = true
    }
  }
}
</script>
<style scoped lang="less">
.add-todo{
  overflow-y: scroll;
}
.add-todo /deep/ .van-tabs__nav--card{
  border-radius: 57px;
  overflow: hidden;
}
.add_need_flex{
  display: flex;
  cursor: pointer;
  justify-content:space-between;
}
.add_need_page{
  padding: 0 35px;
  font-size: 26px;
  color: #333;
}
.tip_cont{
  margin-top: 20px;
}
.tip_row{
  width: 190px;
  display: flex;
  line-height: 60px;
}
.select_time{
  padding: 0 10px;
  border-radius: 10px;
  margin-top: 30px;
  background: #fff;
}
.time_input{
  width: 100px;
  border: 1px solid #D9D9D9;
  margin-right: -10px;
  text-align: center;
}
.tab_time{
  width: 157px;
}
.select_time_style{
  display: flex;
  justify-content:flex-end;
  font-size: 26px;
  width: 50%;
  line-height: 57px;
  cursor: pointer;
}
/deep/ .van-notice-bar__content{
  font-size: 25px;
  letter-spacing: 1px;
}
.tip-icon{
  width: 35px;
  height: 35px;
  margin-top: 8px;
  margin-right: 5px;
}
</style>
