<template>
  <div>
    <Popup
      v-model:show="setRepeatPop"
      round
      position="bottom"
      :close-on-click-overlay="false">
      <!--      !showCustom-->
      <div class="repeat_page show_data" v-if="!showCustom">
        <div style="margin-top: 20px;cursor: pointer;" @click="closeBtn">
          <van-icon name="arrow-left" />
          <span style="font-weight: bold;">重复</span>
        </div>
        <div class="select_repeat">
          <van-list>
            <van-radio-group v-model="checkedGroup">
              <van-cell v-for="(item,index) in repeatType" :key="index">
                <div class="repeat_flex" style="cursor: pointer;" @click="selectType(item)">
                  <div>{{ item.name }}</div>
                  <van-radio :name="item.key"></van-radio>
                </div>
              </van-cell>
            </van-radio-group>
          </van-list>
        </div>
        <div class="select_repeat" style="margin-top: 10px;">
          <van-list>
            <van-cell>
              <div class="repeat_flex" @click="customBtn" style="cursor: pointer;">
                <div>自定义重复</div>
                <div><van-icon name="arrow" /></div>
              </div>
            </van-cell>
          </van-list>
        </div>
      </div>
      <div class="repeat_page show_data" v-else style="padding-bottom: 30px;">
        <div style="margin-top: 20px;cursor: pointer;" @click="showCustom=false">
          <van-icon name="arrow-left" />
          <span style="font-weight: bold;">自定义重复</span>
        </div>
        <div class="select_repeat">
          <van-list>
            <van-cell>
              <div class="repeat_flex" @click="showCustom=true">
                <div style="width: 35px;text-align: center;">频率</div>
                <div>
                  <van-tabs
                    v-model:active="rateType"
                    type="card"
                    class="date_card"
                    title-active-color="#fff"
                    title-inactive-color="#595959"
                    color="#1890FF">
                    <van-tab title="日" :name="1"></van-tab>
                    <van-tab title="周" :name="2"></van-tab>
                    <van-tab title="月" :name="3"></van-tab>
                  </van-tabs>
                </div>
              </div>
            </van-cell>
            <van-cell>
              <div class="repeat_flex" @click="showCustom=true">
                <div style="width: 35px;text-align: center;">每</div>
                <div>
                  <input type="number" class="time_input" v-model="rateNum">
                  <span style="color: #8C8C8C;" v-if="rateType==1">日</span>
                  <span style="color: #8C8C8C;" v-if="rateType==2">周</span>
                  <span style="color: #8C8C8C;" v-if="rateType==3">月</span>
                </div>
              </div>
            </van-cell>
          </van-list>
        </div>
        <div style="margin-top: 15px;font-size: 14px;color: #808082;">
          日程将于每{{ rateNum }}
          <span v-if="rateType==1">日</span>
          <span v-if="rateType==2">周</span>
          <span v-if="rateType==3">月</span>
          重复</div>
        <!--        周-->
        <div>
          <div class="select_repeat" v-if="rateType==2" style="margin-top: 15px;">
            <van-list>
              <van-cell v-for="(item,index) in weekArr" :key="index">
                <div class="repeat_flex" style="cursor: pointer;" @click="selectWeekData(item)">
                  <div>{{ item.name }}</div>
                  <van-checkbox v-model="item.checked"></van-checkbox>
                </div>
              </van-cell>
            </van-list>
          </div>
          <!--        月-->
          <div v-show="rateType==3" style="margin-top: 15px;">
            <Calendar
              type="multiple"
              show-subtitle
              :show-confirm="false"
              :min-date="minDate"
              :max-date="maxDate"
              :show-mark="false"
              :default-date="null"
              :close-on-click-overlay="false"
              :closeable="false"
              @select="onConfirm"
              :poppable="false"
              color="#1989fa"
            />
          </div>
        </div>
      </div>
      <div class="repeat_page" style="margin-bottom: 20px;margin-top: 20px;">
        <van-button type="primary" block @click="comfimBtn">确定</van-button>
      </div>
    </Popup>
  </div>
</template>
<script>
import { Popup, Divider, Calendar, Toast } from 'vant'
export default {
  components: {
    Popup, Divider, Calendar
  },
  data () {
    return {
      setRepeatPop: false,
      repeatType: [
        {
          key: 1,
          name: '每天'
        },
        {
          key: 2,
          name: '每周'
        },
        {
          key: 3,
          name: '每月'
        }
      ],
      showCustom: false,
      weekArr: [
        {
          key: 1,
          name: '星期一'
        },
        {
          key: 2,
          name: '星期二'
        },
        {
          key: 3,
          name: '星期三'
        },
        {
          key: 4,
          name: '星期四'
        },
        {
          key: 5,
          name: '星期五'
        },
        {
          key: 6,
          name: '星期六'
        },
        {
          key: 0,
          name: '星期日'
        }
      ],
      minDate: new Date(2010, 0, 1),
      maxDate: new Date(2010, 0, 31),
      // 设置重复
      checkedGroup: -1,
      rateType: 1,
      rateNum: 1,
      checkedWeek: [],
      monthData: []
    }
  },
  methods: {
    // 选择类型
    selectType (item) {
      this.checkedGroup = item.key
    },
    // 确定按钮
    comfimBtn () {
      if (this.checkedGroup == -1) {
        Toast('请设置重复类型')
        return false
      }
      const repeatData = {
        repeatType: this.checkedGroup
      }
      if (this.checkedGroup == 4) {
        repeatData.rateType = this.rateType
        if (this.rateNum < 1) {
          Toast('最小值为1')
          return false
        }
        repeatData.rateNum = this.rateNum
        if (this.rateType == 2) {
          if (this.checkedWeek.length == 0) {
            Toast('请选择星期')
            return false
          }
          repeatData.dayNum = this.checkedWeek.map((item, index) => {
            return JSON.parse(item).key
          })
        }
        if (this.rateType == 3) {
          if (this.monthData.length == 0) {
            Toast('请选择日期')
            return false
          }
          repeatData.dayNum = JSON.parse(JSON.stringify(this.monthData))
        }
      }
      this.$emit('change', repeatData)
      this.setRepeatPop = false
    },
    onConfirm (e) {
      this.monthData = e.map((item, index) => {
        return item.getDate()
      })
    },
    // 选中周
    selectWeekData (item) {
      const selectData = JSON.parse(JSON.stringify(item))
      selectData.checked = true
      const indexArr = this.checkedWeek.indexOf(JSON.stringify(selectData))
      if (indexArr == -1) {
        item.checked = true
        this.checkedWeek.push(JSON.stringify(selectData))
      } else {
        item.checked = false
        this.checkedWeek.splice(indexArr, 1)
      }
    },
    show () {
      this.setRepeatPop = true
    },
    customBtn () {
      this.showCustom = true
      this.checkedGroup = 4
    },
    closeBtn () {
      this.setRepeatPop = false
    }
  }
}
</script>
<style scoped lang="less">
.repeat_flex{
  display: flex;
  justify-content:space-between;
}
.repeat_page{
  font-size: 28px;
  color: #333;
  padding: 0 35px;
}
.select_repeat{
  border: 1px solid #E8E8E8;
  padding: 0 10px;
  border-radius: 10px;
  margin-top: 30px;
}
.time_input{
  width: 100px;
  border: 1px solid #D9D9D9;
  margin-right: 5px;
  text-align: center;
}
.date_card{
  width: 230px;
  margin-right: -20px;
}
.show_data{
  //min-height: 750px;
  //background: skyblue;
}
/deep/ .van-calendar__header-subtitle{
  display: none;
}
</style>
