<template>
  <div>
    <Popup
      v-model:show="integralPopup"
      position="bottom"
      closeable
      :style="{ height: '90%' }">
      <div style="font-size: 18px;font-weight: bold;text-align: center;padding-top: 15px;">客户积分</div>
      <Divider />
      <div class="score_flex score_page">
        <div style="display: flex;width: calc(100% - 75px);">
          <img src="../../../assets/60.jpg" alt="" style="width: 50px;height: 50px;">
          <div style="margin-left: 10px;width: calc(100% - 60px);">
            <div style="font-size: 16px;font-weight: bold;">她守韶华向晚</div>
            <div class="score_flex" style="margin-top: 8px;color: #999;">
              <van-tag type="warning">未设置</van-tag>
              <div>评分：<span style="font-size: 18px;font-weight: bold;color: #2897FF;">0</span></div>
              <div>今日积分下降：<span>0</span></div>
            </div>
          </div>
        </div>
      </div>
      <Divider />
      <div class="score_page">
        <div class="row_score_name">积分记录</div>
      </div>
      <Divider />
      <div style="margin-top: 15px;padding-bottom: 50px;" >
        <div class="member_modify_list" v-for="(item,index) in 1" :key="index">
          <div class="page_flex" style="font-size: 15px;padding-left: 25px;color:#9F9F9F;">
            <div>星期四  2021-09-02</div>
          </div>
          <Divider style="margin-top: 10px;margin-bottom: 10px;" />
          <div style="padding-left: 20px;">
            <Steps
              direction="vertical"
              active-icon="clock"
              inactive-icon="clock"
            >
              <Step>
                <div class="group_event">加入群聊</div>
                <div class="group_time">12:40 李国强加入群聊【123】</div>
              </Step>
              <Step>
                <div class="group_event">退出群聊</div>
                <div class="group_time">12:40 李国强退出群聊【123】</div>
              </Step>
            </Steps>
          </div>
        </div>
      </div>
    </Popup>
  </div>
</template>
<script>
import { Popup, Divider, Step, Steps } from 'vant'
export default {
  components: {
    Popup, Divider, Step, Steps
  },
  data () {
    return {
      integralPopup: false
    }
  },
  methods: {
    show () {
      this.integralPopup = true
    }
  }
}
</script>
<style scoped lang="less">
.score_flex{
  display: flex;
  justify-content:space-between;
}
.score_page{
  padding: 0 30px 0 30px;
  font-size: 28px;
  color: #333;
}
.modify_score{
  font-size: 25px;
  height: 50px;
  padding: 0 10px 0 10px;
}
.row_score_name{
  color: #333;
  font-size: 29px;
  border-left: 7px solid #1890FF;
  height: 35px;
  line-height: 40px;
  padding-left: 10px;
}
//步骤条
/deep/ .van-step__icon--active{
  color: #42A4FF;
}
/deep/ .van-step__circle-container{
  font-size: 30px;
  color: #42A4FF;
}
/deep/ .van-step__title {
  color: #333;
}
.group_event{
  font-weight: bold;
}
.group_time{
  margin-top: 15px;
  color: #747474;
}
</style>
