<template>
  <div class="component">
    <van-action-sheet v-model="show" title="选择标签">
      <div class="content" style="color: #818181;">
        <van-search style="padding: 5px 16px" v-model="serchValue" placeholder="请输入搜索关键词" />

        <div class="tags-list">
          <div class="item">
            <div class="title">
              测试标签
            </div>
            <div class="tags">
              <div>和我i独家hi</div>
              <div>d无情的</div>
              <div>完全的心脏病i</div>
              <div>和一天就看hi</div>
              <div>绕太阳try</div>
            </div>
          </div>
          <div class="item">
            <div class="title">
              测试标签
            </div>
            <div class="tags">
              <div>和我i独家hi</div>
              <div>d无情的</div>
              <div>完全的心脏病i</div>
              <div>和一天就看hi</div>
              <div>绕太阳try</div>
            </div>
          </div>
          <div class="item">
            <div class="title">
              测试标签
            </div>
            <div class="tags">
              <div>和我i独家hi</div>
              <div>d无情的</div>
              <div>完全的心脏病i</div>
              <div>和一天就看hi</div>
              <div>绕太阳try</div>
            </div>
          </div>
          <div class="item">
            <div class="title">
              测试标签
            </div>
            <div class="tags">
              <div>和我i独家hi</div>
              <div>d无情的</div>
              <div>完全的心脏病i</div>
              <div>和一天就看hi</div>
              <div>绕太阳try</div>
            </div>
          </div>
          <div class="item">
            <div class="title">
              测试标签
            </div>
            <div class="tags">
              <div>和我i独家hi</div>
              <div>d无情的</div>
              <div>完全的心脏病i</div>
              <div>和一天就看hi</div>
              <div>绕太阳try</div>
            </div>
          </div>
        </div>
        <van-button block style="width: 90%;margin: 0 auto;margin-top: 10px;margin-bottom: 10px" type="info">确定</van-button>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show:false,
      radio: '2',
      // 搜索名称
      serchValue: ''
    }
  },
  watch: {},
  methods: {
    $show(){
      this.show = true
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.van-action-sheet__header{
  font-weight: bolder;
}
.component {
  .content{
    padding: 0;
    .tags-list{
      height: 275px;
      overflow: auto;
      padding: 0px 16.5px;
      .item{
        margin-top: 10px;
      }
      .title{
        color: rgba(0,0,0,.85);
        font-size: 14px;
      }
      .tags{
        margin-top: 5px;
        div{
          display: inline-block;
          padding: 3px 10px;
          border: 1px solid #e9e9e9;
          background: #f7f7f7;
          border-radius: 3px;
          margin: 3px 0px;
          margin-right: 7px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
